import React, { useEffect, useState, useRef } from 'react';
import './App.css';
import { RetellWebClient } from 'retell-client-js-sdk';
import InputMask from 'react-input-mask';

interface RegisterCallResponse {
  access_token?: string;
  callId?: string;
  sampleRate: number;
}

type ChatMessage = {
  speaker: 'agent' | 'user';
  text: string;
};

const webClient = new RetellWebClient();

const App = () => {
  const [callStatus, setCallStatus] = useState<
    'not-started' | 'active' | 'inactive'
  >('not-started');
  const [isAgentSpeaking, setIsAgentSpeaking] = useState(false);
  const [agentName, setAgentName] = useState('');
  const [dateOfBirth, setDateOfBirth] = useState('');
  const [chatHistory, setChatHistory] = useState<ChatMessage[]>([]);
  const previousTranscriptRef = useRef<{ role: string; content: string }[]>([]);
  const chatContainerRef = useRef<HTMLDivElement>(null);

  // Added zipCode constant
  const zipCode = '19019';

  // Error messages state
  const [errors, setErrors] = useState<{ name?: string; dob?: string }>({});

  useEffect(() => {
    webClient.on('conversationStarted', () => {
      console.log('conversationStarted');
      setCallStatus('active');
      setIsAgentSpeaking(true);
    });

    webClient.on('conversationEnded', ({ code, reason }) => {
      console.log('Closed with code:', code, ', reason:', reason);
      setCallStatus('inactive');
      setIsAgentSpeaking(false);
    });

    webClient.on('error', (error) => {
      console.error('An error occurred:', error);
      setCallStatus('inactive');
      setIsAgentSpeaking(false);
    });

    webClient.on('update', (update) => {
      console.log('update', update);

      // Update agent speaking status
      if (update.turntaking === 'user_turn') {
        setIsAgentSpeaking(false);
      } else if (update.turntaking === 'agent_turn') {
        setIsAgentSpeaking(true);
      }

      // Process the transcript
      if (update.transcript) {
        const newTranscript = update.transcript; // Array of messages
        const previousTranscript = previousTranscriptRef.current || [];

        setChatHistory((prevHistory) => {
          const updatedChatHistory = [...prevHistory];

          const newTranscriptLength = newTranscript.length;
          const prevTranscriptLength = previousTranscript.length;

          if (newTranscriptLength === 0) {
            // No messages
            return updatedChatHistory;
          }

          if (newTranscriptLength > prevTranscriptLength) {
            // A new message has been added
            const newMsg = newTranscript[newTranscriptLength - 1];
            updatedChatHistory.push({
              speaker: newMsg.role === 'agent' ? 'agent' : 'user',
              text: newMsg.content,
            });
          } else if (newTranscriptLength === prevTranscriptLength) {
            // The last message content may have been updated
            const newMsg = newTranscript[newTranscriptLength - 1];
            const prevMsg = previousTranscript[prevTranscriptLength - 1];

            if (newMsg.content !== prevMsg.content) {
              // Update the last message in chat history
              const lastChatMsgIndex = updatedChatHistory.length - 1;
              const lastChatMsg = updatedChatHistory[lastChatMsgIndex];

              if (
                lastChatMsg &&
                lastChatMsg.speaker ===
                  (newMsg.role === 'agent' ? 'agent' : 'user')
              ) {
                updatedChatHistory[lastChatMsgIndex].text = newMsg.content;
              } else {
                // If speakers don't match, add a new message
                updatedChatHistory.push({
                  speaker: newMsg.role === 'agent' ? 'agent' : 'user',
                  text: newMsg.content,
                });
              }
            }
          } else {
            // Handle unexpected decrease in transcript length
            console.warn(
              'Transcript length decreased, this may cause issues with chat history sync.'
            );
          }

          return updatedChatHistory;
        });

        // Update the previous transcript
        previousTranscriptRef.current = newTranscript;
      }
    });
  }, []);

  // Auto-scroll to the latest message
  useEffect(() => {
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
    }
  }, [chatHistory]);

  const toggleConversation = async () => {
    if (callStatus === 'active') {
      webClient.stopCall();
      setCallStatus('inactive');
      // Do not clear chat history
      // setChatHistory([]);
      // previousTranscriptRef.current = [];
    } else {
      const validationErrors: { name?: string; dob?: string } = {};

      if (agentName.trim() === '') {
        validationErrors.name = 'Please enter your full name (minimum two words).';
      } else {
        // Name field validation for minimum two words
        const nameWords = agentName.trim().split(/\s+/);
        if (nameWords.length < 2) {
          validationErrors.name = 'Please enter your full name (minimum two words).';
        }
      }

      // Date of Birth validation
      if (!dateOfBirth || dateOfBirth.includes('_')) {
        validationErrors.dob = 'Please enter your date of birth in MM/DD/YYYY format.';
      } else {
        const age = calculateAge(dateOfBirth);
        if (age === null) {
          validationErrors.dob = 'Please enter a valid date of birth.';
        } else if (age < 10) {
          validationErrors.dob = 'Age must be at least 10 years.';
        }
      }

      if (Object.keys(validationErrors).length > 0) {
        setErrors(validationErrors);
        return;
      }

      // Clear errors if validation passes
      setErrors({});

      setCallStatus('active');
      setIsAgentSpeaking(true);
      setChatHistory([]); // Clear the old transcript when starting a new call
      previousTranscriptRef.current = []; // Clear previous transcript reference

      const agentId = 'agent_6838c9a7a9475565ff4190ecfd';
      try {
        const registerCallResponse = await registerCall(agentId);
        if (registerCallResponse.callId) {
          webClient
            .startCall({
              accessToken: registerCallResponse.access_token,
              callId: registerCallResponse.callId,
              sampleRate: registerCallResponse.sampleRate,
              enableUpdate: true,
            })
            .catch(console.error);
        }
      } catch (error) {
        console.error('Failed to start the call:', error);
      }
    }
  };

  async function registerCall(agentId: string): Promise<RegisterCallResponse> {
    console.log('Registering call for agent:', agentId);

    const apiKey = process.env.REACT_APP_RETELL_API;
    const sampleRate = parseInt(
      process.env.REACT_APP_RETELL_SAMPLE_RATE || '16000',
      10
    );

    console.log('Using Agent ID:', agentId);
    console.log('API Key (first 4 chars):', apiKey?.substring(0, 4));
    console.log('Sample Rate:', sampleRate);

    try {
      const firstName = agentName.split(' ')[0];
      
      const response = await fetch(
        'https://api.retellai.com/v2/create-web-call',
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${apiKey}`,
          },
          body: JSON.stringify({
            agent_id: agentId,
            retell_llm_dynamic_variables: {
              member_name: agentName,
              first_name: firstName,
              date_of_birth: dateOfBirth,
              zip_code: zipCode, // Added zip_code to the payload
            },
          }),
        }
      );

      if (!response.ok) {
        throw new Error(`Error: ${response.status}`);
      }

      const data = await response.json();
      console.log('Call registered successfully:', data);

      return {
        access_token: data.access_token,
        callId: data.call_id,
        sampleRate: sampleRate,
      };
    } catch (err) {
      console.error('Error registering call:', err);
      throw new Error(String(err));
    }
  }

  // Function to calculate age from date string in MM/DD/YYYY format
  function calculateAge(dobString: string): number | null {
    const [month, day, year] = dobString.split('/').map(Number);
    const dob = new Date(year, month - 1, day);

    if (
      isNaN(dob.getTime()) ||
      dob.getFullYear() !== year ||
      dob.getMonth() !== month - 1 ||
      dob.getDate() !== day
    ) {
      return null; // Invalid date
    }

    const today = new Date();
    let age = today.getFullYear() - dob.getFullYear();
    const m = today.getMonth() - dob.getMonth();

    if (m < 0 || (m === 0 && today.getDate() < dob.getDate())) {
      age--;
    }

    return age;
  }

  return (
    <div className="App">
      {/* Wrap main content and disclaimer in a container */}
      <div className="content-wrapper">
        <div className="main-container">
          {/* Left panel: the form */}
          <div className="form-container">
            {/* Small logo at top left */}
            <div className="small-logo-container">
              <img
                src={`${process.env.PUBLIC_URL}/everai.png`}
                alt="Ever AI Small Logo"
                className="small-logo"
              />
            </div>

            {/* Existing Logo */}
            <div className="logo-container">
              <img
                src={`${process.env.PUBLIC_URL}/EverAi_Voicebot.png`}
                alt="Ever AI Logo"
                className="logo"
              />
            </div>

            <div className="space-y-4">
              <div className="input-group">
                <label className="label">Enter Full Name:</label>
                <input
                  type="text"
                  value={agentName}
                  onChange={(e) => setAgentName(e.target.value)}
                  className={`text-input ${errors.name ? 'input-error' : ''}`}
                  disabled={callStatus === 'active'}
                  placeholder="Enter member name"
                />
                {errors.name && <span className="error-text">{errors.name}</span>}
              </div>

              <div className="input-group">
                <label className="label">Choose Date of Birth:</label>
                <InputMask
                  mask="99/99/9999"
                  value={dateOfBirth}
                  onChange={(e) => setDateOfBirth(e.target.value)}
                  disabled={callStatus === 'active'}
                >
                  {(inputProps) => (
                    <input
                      {...inputProps}
                      type="text"
                      className={`text-input ${errors.dob ? 'input-error' : ''}`}
                      placeholder="MM/DD/YYYY"
                    />
                  )}
                </InputMask>
                {errors.dob && <span className="error-text">{errors.dob}</span>}
              </div>

              {/* Added Zip Code field */}
              <div className="input-group">
                <label className="label">
                  Zip Code:
                  <span className="prefilled-text">
                    (Prefilled)
                    <span className="red-asterisk">*</span>
                  </span>
                </label>
                <input
                  type="text"
                  value={zipCode}
                  className="text-input zip-code-input"
                  readOnly
                />
              </div>

              <button
                onClick={toggleConversation}
                className={`action-button ${
                  callStatus === 'active' ? 'end-call-button' : 'start-call-button'
                }`}
                disabled={
                  callStatus === 'not-started' &&
                  (agentName === '' || dateOfBirth === '')
                }
              >
                {callStatus === 'active' ? 'End Call' : 'Start Call'}
              </button>

              {callStatus === 'active' && (
                <p className="disclaimer centered">Click end call to disconnect</p>
              )}
            </div>
          </div>

          {/* Right panel: the chat */}
          <div className="chat-container" ref={chatContainerRef}>
            {chatHistory.map((message, index) => (
              <div
                key={index}
                className={`message ${
                  message.speaker === 'agent' ? 'agent' : 'user'
                }`}
              >
                <p>{message.text}</p>
              </div>
            ))}
          </div>
        </div>

        {/* Disclaimer box */}
        <div className="disclaimer-box">
          <h2>Disclaimer:</h2>
          <ul>
            <li>
              This BOT is a prototype for demo purpose and is not integrated with any
              production system.
            </li>
            <li>
              Objective of the BOT is to get a PCP selected and get an appointment
              scheduled with the selected PCP by the member.
            </li>
            <li>
              The Member name and DOB is being asked by the BOT to initiate discussion
              and use these details to verify customer during the conversation. In real
              production scenario these information will be available to the BOT via IBC
              system integration.
            </li>
            <li>
              <span className="red-asterisk">*</span>
              The BOT has been updated with actual IBC PCP details as available on IBC
              website for zip code 19019. So, for the purpose of this test, please use
              zip code as 19019 when prompted by the bot.
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default App;
